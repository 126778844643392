<script>
import ViewAllTemplate from './ViewAllTemplate.vue'
import CandidatureListItem from '@/components/general/CandidatureListItem.vue'
import ModalMessages from '@/components/general/ModalMessages'
import Breadcrumbs from '@/components/general/Breadcrumbs'
export default {
  name: 'UnderAnalysisCandidatures',
  components: { ViewAllTemplate, CandidatureListItem, ModalMessages, Breadcrumbs },
  data () {
    return {
      company: {},
      position: {},
      showModalHistory: false,
      closedMatches: [],
      isReady: false,
      buttonText: ''
    }
  },
  created () {
    if (this.selectedWorkspace.type !== 'business') this.buttonText = this.$t('empty.state.button:discovery.jobs')
    this.$store.dispatch('attemptSetShowSpinner', true)
    this.$store.dispatch('attemptGetUserClosedMatches', { aggregator: this.getCompanyDomainAndWorkspace() }).then((data) => {
      this.closedMatches = data
      this.$store.dispatch('attemptSetShowSpinner', false)
      this.isReady = true
    })
  },
  computed: {
    breadcrumbsPaths () {
      const isBusiness = this.$route.path.includes('/c/')
      const path = [
        {
          text: isBusiness ? 'internal.mobility:title' : 'main.header:candidacy',
          href: isBusiness ? this.$router.resolve({ name: 'business.internal.positions', params: { companyDomain: this.selectedWorkspace.subdomain } }).href : this.$router.resolve({ name: this.selectedWorkspace.type + '.candidature.index' }).href
        },
        {
          text: 'internal.mobility:finished',
          href: this.$route.path
        }
      ]
      return path
    }
  },
  methods: {
    handleShowModalMessagesHistory (item) {
      this.company = { name: item.position.company.name, image: item.position.company.logo }
      this.position = { name: item.position.title, id: item.position.id }
      this.showModalHistory = !this.showModalHistory
    },
    btnClick () {
      if (this.selectedWorkspace.type !== 'business') this.$router.push({ name: this.selectedWorkspace.type + '.discovery.index' })
    }
  }
}
</script>
<template>
  <div v-bind:class="{ 'center-small': selectedWorkspace.type === 'business' }">
    <div class="default-header" v-if="selectedWorkspace.type === 'business'">
      <breadcrumbs :items="breadcrumbsPaths" />
      <h2>{{ $t("internal.mobility:finished") }}</h2>
    </div>
    <view-all-template description="candidature.list:closed.subtitle" :items="closedMatches">
      <template v-slot:item="slotProps">
        <candidature-list-item :candidature="slotProps.item" forceStatus="closed"
          @handleModalMessageCandidatureHistory="handleShowModalMessagesHistory(slotProps.item)"></candidature-list-item>
      </template>
    </view-all-template>
    <modal-messages v-if="showModalHistory" :readonly="true" :company=company :position=position
      @close="showModalHistory = false"></modal-messages>

  </div>
</template>
<style lang="scss">

</style>
